@import "~@ui/styles/tools";

.title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.icon {
    padding: 12px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #fff;

    @include media-tablet {
        width: 42px;
        height: 42px;
        padding: 8px;
    }
}

.action-button {
    display: none;
}

@include media-mobile {
    .action {
        display: none;
    }

    .action-button {
        margin-top: 20px;
        display: block;
    }
}