@import "~@ui/styles/tools";
@import "src/styles/variables";

.card {
    background: $color-page-background;
    border-radius: 12px;
    margin: 0 10px;
}

@include media-mobile {
    .card {
        border: 1px solid $color-light-gray;
    }

    .card--no-border {
        border: none;
    }
}