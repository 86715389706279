@import "../../styles/tools";

.wrapper {
    display: inline-flex;
    align-items: center;
}

.label {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    color: $color-body;

    &:first-child {
        margin-right: 20px;
    }

    &:last-child {
        margin-left: 20px;
    }
}

.container {
    display: inline-block;
}

.button {
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 5px;
}

.button__icon {
    width: 32px;
}

.container--success:hover {
    .button__icon path {
        fill: $color-primary;
    }

    .button:hover ~ .button {
        .button__icon path {
            fill: $color-off-white;
        }
    }
}

.container--warning:hover {
    .button__icon path {
        fill: $color-warning;
    }

    .button:hover ~ .button {
        .button__icon path {
            fill: $color-off-white;
        }
    }
}

.container--error:hover {
    .button__icon path {
        fill: $color-error;
    }

    .button:hover ~ .button {
        .button__icon path {
            fill: $color-off-white;
        }
    }
}

.button--selected--success {
    .button__icon path {
        fill: $color-primary;
    }
}

.button--selected--warning {
    .button__icon path {
        fill: $color-warning;
    }
}

.button--selected--error {
    .button__icon path {
        fill: $color-error;
    }
}

.button--readonly {
    pointer-events: none;
}

.container--size-large {
    .button__icon {
        width: 52px;
    }
}

.container--size-small {
    .button {
        padding: 0 2px;
    }

    .button__icon {
        width: 12px;
    }
}

@include media-mobile {
    .wrapper {
        flex-direction: column;
    }

    .labels {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .label {
            max-width: 30%;

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }
    }
}
