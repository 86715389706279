@import "~@ui/styles/tools";

@mixin medium-breakpoint {
  @media screen and (max-width: 1450px) {
    @content;
  }
}

.container {
  display: grid;
  grid-template-areas:
  ". . ."
  ". . ."
  "last last last";
  gap: 10px;

  @include media-tablet {
    grid-template-areas:
  ". ."
  ". ."
  ". ."
  "last last";
  }

  @include media-tablet-portrait {
    grid-template-areas: none;
    grid-template-columns: 1fr;
  }
}

.full-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @include medium-breakpoint {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-tablet-portrait {
    grid-template-columns: 1fr;
  }
}

.gray {
  background-color: #F6F7F8 !important;
}

.last {
  grid-area: last;
  @include media-tablet-portrait {
    grid-area: auto;
  }
}

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  color: #1E2021;

  @include media-mobile {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
  }
}

.description {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  color: #8C8C8C;
  margin-top: 16px;

  @include media-mobile {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }
}