@import "~@ui/styles/tools";

.slick-dots {
    list-style: none;
    display: flex !important;
    gap: 10px;
    justify-content: center;

    button {
        display: inline-block;
        padding: 0;
        width: 10px;
        height: 10px;
        background: #333;
        border-radius: 50%;
        opacity: 0.25;
        cursor: pointer;
        border: none;
        outline: none;
        color: transparent;
    }

    .slick-active {
        button {
            background: #b1b1b1;
            opacity: 1;
        }
    }

    @include media-tablet {
        margin-bottom: 0;
        margin-top: 10px;
    }
}

.slick-slider {
    position: relative;
    overflow-y: clip;

    &:hover {
        .slick-arrow {
            cursor: pointer;
            opacity: 1;
        }
    }
}

.slick-track {
    display: flex;
}

.slick-arrow {
    position: absolute;
    top: 50%;
    padding: 10px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    transform: translateY(-50%);
    background: hsla(0, 0%, 100%, .75);
    border: none;
    z-index: 2;
    opacity: 0;
    transition: .5s ease;
    
    &:hover {
        cursor: pointer;
        opacity: 1;
    }
}

.slick-prev {
    left: 20px;
}
.slick-next {
    right: 20px;
}