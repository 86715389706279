@import "~@ui/styles/tools";

.container {
    --markup-block--vs: 38px;
    --markup-block--spacing: 32px;
    --markup-block--title-size: 32px;
    --markup-block--icon-size: 105px;
    --markup-block--content-size: 20px;

    display: flex;
    flex-direction: column;
    border-radius: 24px;
    border-bottom: 2px solid $color-primary;
    padding: var(--markup-block--vs) var(--markup-block--spacing) 0;
    background: #F6F7F8;

    @include media-desktop-md {
        --markup-block--vs: 32px;
        --markup-block--title-size: 28px;
    }

    @include media-tablet{
        --markup-block--vs: 25px;
        --markup-block--spacing: 20px;
        --markup-block--icon-size: 82px;
        --markup-block--title-size: 22px;
        --markup-block--content-size: 16px;
    }

    @include media-tablet-portrait {
        --markup-block--spacing: 16px;
        --markup-block--title-size: 18px;
        --markup-block--content-size: 14px;
    }
}

.header {
    border-bottom: 1px solid #130B2833;
}

.image {
    width: var(--markup-block--icon-size);
    height: var(--markup-block--icon-size);
    margin-bottom: var(--markup-block--spacing);

    img {
        width: var(--markup-block--icon-size);
        height: var(--markup-block--icon-size);
    }
}

.title {
    color: $color-title-active;
    font-weight: 500;
    font-size: var(--markup-block--title-size);
    line-height: 110%;
    margin-bottom: var(--markup-block--spacing);
    // Устанавливаем высоту из переменной, чтобы можно было синхронизировать высоту с другими карточками
    // Переменная рассчитывается в родительском компоненте - MarkupBlocksSection
    min-height: var(--markup-block--header-height);
}

.content {
    margin-top: var(--markup-block--spacing);
    margin-bottom: var(--markup-block--vs);

    & > ul {
        font-size: var(--markup-block--content-size);

        // Если блок находится на границах, то сбрасываем собственные отступы, чтобы использовался общий отступ контейнера
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    li {
        color: #8C8C8C;
        font-weight: 400;

        &::marker {
            color: $color-title-active;
        }
    }

    b, em, strong {
        font-weight: 600;
        color: $color-title-active;
    }
}