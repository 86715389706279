@import "~@ui/styles/tools";

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
    padding: 0 8%;

    @include media-desktop-md {
        gap: 11px;
        padding: 0 40px;
    }

    @media screen and (max-width: 1000px) {
        gap: 12px;
        padding: 0 20px;
        flex-direction: column;
    }
}

