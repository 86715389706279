@import "~@ui/styles/tools";
@import "../../../../styles/variables";

.container {
    padding: 24px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
        padding: 0;
        margin: 0;
    }
}

.top-block {
    display: flex;
    flex-direction: column;

    .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: $color-title;
        margin-bottom: 16px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 29px;
        color: $color-gray;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}

.first-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.image-block {
    display: flex;
    gap: 20px;
    align-items: center;

    .image-circle {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        overflow: hidden;
        background: $color-grey;
    }
}

.title-container {
    flex: 1;

    .name {
        font-size: 26px;
        font-weight: 600;
        line-height: 31px;
        color: $color-title;
        text-transform: capitalize;
    }
}

.bottom-block {
    display: flex;
    justify-content: space-between;

    .more {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
    }

    .date {
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        color: $color-gray;
    }
}

@include media-mobile {
    .container {
        padding: 20px;
        height: 250px;
    }

    .top-block {
        .text {
            font-size: 14px;
            line-height: 25px;
            -webkit-line-clamp: 2;
        }

        .title {
            margin-bottom: 8px;
        }
    }

    .first-block {
        margin-bottom: 22px;
    }

    .image-block {
        gap: 15px;

        .name {
            font-size: 16px;
            line-height: 19px;
        }
    }

    .bottom-block {
        .more {
            font-size: 14px;
            line-height: 16px;
        }

        .date {
            font-size: 14px;
            line-height: 24px;
        }
    }

    .bottom-block {
        .more {
            font-size: 14px;
        }
    }

    .text {
        font-size: 14px;
    }
}