@import "~@ui/styles/tools";

.wrapper {
    display: grid;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);
    border-radius: 24px;
}

.container {
    position: relative;
    aspect-ratio: calc(1280 / 720);
}

.preview-image {
    object-fit: contain;
    transition: opacity 0.2s ease-in-out;
}

.video {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.video--loaded {
    opacity: 1;
}

.container, .video, .preview {
    border-radius: 25px;
    overflow: hidden;
}

.play-button {
    display: flex;
    justify-content: center;
    margin-top: -50px;
}

@include media-mobile {
    .container {
        // 720 / 1280
        aspect-ratio: 0.5625;
    }

    .container, .video, .preview {
        border-radius: 12px;
    }
}
