@import "~@ui/styles/tools";

.button {
    display: none;
}

@include media-mobile {
    .action {
        display: none;
    }

    .button {
        display: block;
        margin-top: 16px;
    }
}