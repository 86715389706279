@import "~@ui/styles/tools";

.academy-news-slider {

    &:hover.slick-slider {
        .slick-arrow {
            opacity: .6;
        }
    }

    .slick-track {
        display: flex;
        gap: 20px;
        padding-bottom: 25px;
    }

    .slick-slide {
        > div{
            height: 100%;

            > div {
                height: 100%;
            }
        }
    }

    .slick-arrow {
        position: absolute;
        top: calc(50% - 25px);
        padding: 17.5px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #E0E0E0;
        border: none;
        z-index: 2;
        opacity: 0;
        transition: .5s ease;

        &:hover {
            cursor: pointer;
        }
    }

    .slick-prev {
        left: 5px;
    }

    .slick-next {
        right: 5px;
    }

    .slick-disabled {
        display: none !important;
    }
}